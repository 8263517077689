<template>
    <div>
      <!-- 메뉴 -->
      <nav
        v-show="$store.state.isLogin"
        class="navbar navbar-expand-lg navbar-dark bg-primary"
        id="navsmenu"
      >
        <span
          style="color: white; font-weight: 600; font-size: 20px; width: 40%"
          @click="this.$router.push('/dashboard')"
          data-toggle="collapse"
          data-target="#navbarText"
        >청년부 만나</span>
        <div style="width: 40%; text-align: right; color: white">
          ♡
          {{ $store.state.name }}
        </div>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarText"
          aria-controls="navbarText"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
       
        <div class="collapse navbar-collapse" id="navbarText">
          <ul class="navbar-nav mr-auto">
            <!-- 메뉴 항목 -->
            <li class="nav-item">
              <button
                v-show="$store.state.role < 5"
                type="button"
                class="btn btn-primary btn-sm"
                style="width: 100px; font-size: 1em; font-weight: 500"
                @click="this.$router.push('/studentlist')"
                data-toggle="collapse"
                data-target="#navbarText"
              >
                셀원목록
              </button>
            </li>
            <li class="nav-item">
              <button
                v-show="$store.state.role < 5"
                type="button"
                class="btn btn-primary btn-sm"
                style="width: 100px; font-size: 1em; font-weight: 500"
                @click="this.$router.push('./studentassign')"
                data-toggle="collapse"
                data-target="#navbarText"
              >
                셀원편성
              </button>
            </li>          
            <li class="nav-item">
              <button
                v-show="$store.state.grade > 0"
                type="button"
                class="btn btn-primary btn-sm"
                style="width: 100px; font-size: 1em; font-weight: 500"
                @click="this.$router.push('./attendance')"
                data-toggle="collapse"
                data-target="#navbarText"
              >
                셀 보고서
              </button>
            </li>
            <li class="nav-item">
              <button
                v-show="$store.state.role == 3 || $store.state.role == 1"
                type="button"
                class="btn btn-primary btn-sm"
                style="width: 120px; font-size: 1em; font-weight: 500"
                @click="this.$router.push('./weeklyreport')"
                data-toggle="collapse"
                data-target="#navbarText"
              >
                셀보고현황
              </button>
            </li>
            <li class="nav-item">
              <button
                v-show="$store.state.grade > 0"
                type="button"
                class="btn btn-primary btn-sm"
                style="width: 100px; font-size: 1em; font-weight: 500"
                @click="this.$router.push('./attendtotal')"
                data-toggle="collapse"
                data-target="#navbarText"
              >
                출결집계
              </button>
            </li>
            <li class="nav-item">
              <button
                type="button"
                class="btn btn-primary btn-sm"
                style="width: 100px; font-size: 1em; font-weight: 500"
                @click="this.$router.push('./attendallcount')"
                data-toggle="collapse"
                data-target="#navbarText"
              >
                전체출결
              </button>
            </li>
            <li class="nav-item">
              <button
                type="button"
                class="btn btn-primary btn-sm"
                style="width: 100px; font-size: 1em; font-weight: 500"
                @click="this.$router.push('./notice')"
                data-toggle="collapse"
                data-target="#navbarText"
              >
                부서 공지
              </button>
            </li>
            <li class="nav-item">
              <button
                type="button"
                class="btn btn-primary btn-sm"
                style="width: 100px; font-size: 1em; font-weight: 500"
                @click="this.$router.push('./allnotice')"
                data-toggle="collapse"
                data-target="#navbarText"
              >
                전체 공지
              </button>
            </li>            
            <li class="nav-item">
              <button
                type="button"
                class="btn btn-primary btn-sm"
                style="width: 100px; font-size: 1em; font-weight: 500"
                @click="this.$router.push('./study')"
                data-toggle="collapse"
                data-target="#navbarText"
              >
                셀 자료
              </button>
            </li>
            <li class="nav-item">
              <button
                v-show="$store.state.role < 2"
                type="button"
                class="btn btn-primary btn-sm"
                style="width: 120px; font-size: 1em; font-weight: 500"
                @click="this.$router.push('./reports')"
                data-toggle="collapse"
                data-target="#navbarText"
              >
                셀 보고현황
              </button>
            </li>
            <li class="nav-item">
              <button
                v-show="$store.state.role == 1 || $store.state.role == 3"
                type="button"
                class="btn btn-primary btn-sm"
                style="width: 120px; font-size: 1em; font-weight: 500"
                @click="this.$router.push('./personreport')"
                data-toggle="collapse"
                data-target="#navbarText"
              >
                셀원별 보고서
              </button>
            </li>
            <li class="nav-item">
              <button
                type="button"
                class="btn btn-primary btn-sm"
                style="width: 120px; font-size: 1em; font-weight: 500"
                @click="this.$router.push('./pray')"
                data-toggle="collapse"
                data-target="#navbarText"
              >
                중보기도수첩
              </button>
            </li>           
            <!-- <li class="nav-item">
              <button
                v-show="$store.state.role < 2"
                type="button"
                class="btn btn-primary btn-sm"
                style="width: 120px; font-size: 1em; font-weight: 500"
                @click="this.$router.push('./newsletter')"
                data-toggle="collapse"
                data-target="#navbarText"
              >
                주보등록
              </button>
            </li>           -->
            <li class="nav-item">
              <button
                v-show="$store.state.isLogin"
                type="button"
                class="btn btn-primary btn-sm"
                style="width: 100px; font-size: 1em; font-weight: 500"
                @click="this.$router.push('./logout')"
                data-toggle="collapse"
                data-target="#navbarText"
              >
                로그아웃
              </button>
            </li>
          </ul>
          <span class="navbar-text"></span>
        </div>
      </nav>
      <!-- 메뉴 -->
  
      <div class="container mt-3">
        <router-view />
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "app",
    created() {
      this.$store.dispatch('checkLoginState');
    },
    methods: {
      logout() {
        this.$store.dispatch('logout');
        this.$router.push('/login');
      }
    }
  };
  </script>
  
  <style>
  body {
    font-family: "Noto Sans KR", "Helvetica Neue", sans-serif;
  }
  a {
    color: #369;
    text-decoration: none;
  }
  </style>
  